// UserRegistration.tsx
import { Box, Button, Paper, Snackbar, TextField, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { redirectToDashboard, reloadState, setErrorMessage } from './utils/globals';
import { getCookie, setCookie } from './utils/cookie';

const UserRegistration: React.FC = () => {
  // Re-render on demand
  const [forceUpdate, setForceUpdate] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [product_key, setproduct_key] = useState(''); // the product key user needs to enter to register

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const reloadPage = () => {
    setForceUpdate(prevState => !prevState);
  };
  const snackError: (error: string) => void = (error: string) => {
    setError(error);
    reloadPage();
  }
  const snackSuccess: (error: string) => void = (error: string) => {
    setSuccessMessage(error);
    reloadPage();
  }


  const handleRegister = async () => {
    if (username.length < 3 ||
      password.length < 8 ||
      confirmPassword.length < 8 ||
      product_key.length < 3) {
      snackError('Please fill all fields');
      return;
    }
    if (username.length > 16 ||
      password.length > 24 ||
      confirmPassword.length > 24 ||
      product_key.length > 50) {
      snackError('Fields too long');
      return;
    }
    if (password !== confirmPassword) {
      snackError("Passwords don't match!");
      return;
    }
    try {
      const response = await axios.post('/user/register', {
        username: username,
        password: password,
        product_key: product_key
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200 &&
        response.data.authorized === true &&
        response.data.username === username) {
        setCookie('username', username, 7);
        setCookie('token', response.data.token, 7);
        setCookie('user_id', response.data.user_id, 7);
        setCookie('auth_level', response.data.auth_level, 7);

        console.log("Login successful");
        /*  console.log("username set : " + getCookie('username'));
         console.log("token set : " + getCookie('token'));
         console.log("user_id set : " + getCookie('user_id'));
         console.log("auth_level set : " + getCookie('auth_level')); */


        setSuccessMessage('Registered and logged in, Redirecting...');
        reloadState();
        setTimeout(redirectToDashboard, 3000);
        return;
      } else {
        setErrorMessage("Error With Automatic login" + response.data);
        return;
      }
    }
    catch (error) {
      console.error('Error Registering User:', error);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError; // Type assertion
        if (axiosError.response && axiosError.response.data) {
          // If the response has a body, use it as the error message
          snackError("Error : " + axiosError.response.data as string);
        } else {
          snackError('Error Registering. Please try again later.');
        }
      } else {
        // Handle other types of errors if needed
        snackError('Error Registering. Please try again later.');
      }
    }
  };


  const handleUsernameChange = (value: string) => {
    const username = value;
    if (/\s/.test(username)) {
      // It has any kind of whitespace
      setErrorMessage("Username should not contain whitespace");
    } else {
      setUsername(username);
    }
  }

  const handlePasswordChange = (value: string) => {
    const password = value;
    if (/\s/.test(password)) {
      setErrorMessage("Password should not contain whitespace");
    } else {
      setPassword(password);
    }
  }

  return (
    <Box sx={{
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      gap: 4,
      height: "100vh",
    }}>
      <Paper elevation={10} sx={{
        padding: "2.5%",
        margin: "10",
        justifyContent: "space-evenly",
        display: "flex",
        flexDirection: "column",
      }}>
        <Typography variant="h5" sx={{ marginBottom: "5px" }}>User Registration</Typography>
        <TextField
          label="Username"
          id='username'
          variant="outlined"
          value={username}
          onChange={(e) => handleUsernameChange(e.target.value)}
          inputProps={{ maxLength: 16 }}
          margin='dense'
        />
        <Box sx={{ display: "flex", flexDirection: 'row' }}>
          <TextField
            sx={{ marginRight: "5px" }}
            id="standard-password-input"
            type="password"
            label="Password"
            variant="outlined"
            autoComplete="current-password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            margin='dense'
            inputProps={{ maxLength: 24 }}
          />
          <TextField
            id="standard-password-input"
            type="password"
            label="Confirm Password"
            variant="outlined"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin='dense'
            inputProps={{ maxLength: 24 }}
          />
        </Box>

        <TextField
          label="Product Key"
          id='product_key'
          variant="outlined"
          value={product_key}
          onChange={(e) => setproduct_key(e.target.value)}
          inputProps={{ maxLength: 50 }}
          margin='dense'
        />

        <Typography variant="caption" sx={{ marginBottom: "5px" }}>To prevent spam, we require a subscription key to be activated on user signup</Typography>
        <Button variant='contained' onClick={handleRegister}>Register</Button>
      </Paper>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => snackError('')}
        message={error}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => snackSuccess('')}
        message={successMessage}
      />
    </Box>
  );
};

export default UserRegistration;
