import { Box, Paper, Typography, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { redirectToregister, redirectToLogin } from './utils/globals';

export const HomePage: React.FC = () => {
    return (
        <Box sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flex: '1'
        }}>
            <Paper elevation={15}
                sx={{
                    p: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                    color: '#fff',
                    backgroundColor: '#111',
                }}>
                <Paper elevation={5}
                    sx={{
                        backgroundColor: '#000',

                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                    }}>
                    <Typography>
                        Don't have an account?
                    </Typography>

                    <Button variant="contained" onClick={redirectToregister}
                        sx={{ scale: "110%", p: 3 }}>
                        Register
                    </Button>
                </Paper >

                <Paper elevation={5}
                    sx={{
                        backgroundColor: '#000',
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                    }}>
                    <Typography>
                        Already have an account?
                    </Typography>
                    <Button variant="contained" onClick={redirectToLogin}
                        sx={{ scale: "110%", p: 3 }}>
                        Login
                    </Button>
                </Paper>
            </Paper>


        </Box >

    );
};
