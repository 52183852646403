import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#90b4fd',
            light: '#8690e0',
        },
        secondary: {
            main: '#29f5e7',
        },
        background: {
            default: 'rgb(0, 0, 0)',
        },
        text: {
            primary: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Barlow Condensed',
        fontSize: 22,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 1000,
    },
    spacing: 8,
    shape: {
        borderRadius: 20,
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: "15px",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "20px",
                    background: "rgba(2,2,2, 0.95)", /* 141424 */
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 46,
                    height: 27,
                    padding: 0,
                    margin: 8,
                },
                switchBase: {
                    padding: 1,
                    '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
                        transform: 'translateX(16px)',
                        color: '#fff',
                        '& + $track': {
                            opacity: 1,
                            border: 'none',
                        },
                    },
                },
                thumb: {
                    width: 24,
                    height: 24,
                },
                track: {
                    borderRadius: 13,
                    border: '1px solid #bdbdbd',
                    backgroundColor: '#fafafa',
                    opacity: 1,
                    transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Encode Sans Condensed',
                    background: 'linear-gradient(30deg, #101010 30%, #303030 90%)',
                    border: 0,
                    borderRadius: 3,
                    boxShadow: '0 3px 5px 2px rgba(20, 20, 20, .3)',
                    color: 'white',
                    height: 48,
                    padding: '0 30px',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: '#141414',
                    height: 15,
                    borderRadius: 2,
                },
                bar: {
                    color: '#343434',
                    backgroundColor: '#343434',
                    borderRadius: 10,
                },
            },
        },
    },
};