import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { themeOptions } from './themeOptions';
// Barlow Condensed  https://fonts.google.com/specimen/Barlow+Condensed
// Bebas Neue  https://fonts.google.com/specimen/Bebas+Neue?preview.text=Serenity
// Hedvig Letters Sans  https://fonts.google.com/specimen/Hedvig+Letters+Sans?preview.text=Serenity

const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);


