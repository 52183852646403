import { Box, Button, Paper, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { getCookie } from "./utils/cookie";
import { logout } from "./utils/session";
import { useCallback, useEffect, useState } from "react";
import { redirectToHome, setReloadState } from "./utils/globals";
import { drawerWidth } from "./utils/globals";
import { SnackbarProvider } from 'notistack';

export default function Layout() {
    const [forceUpdate, setForceUpdate] = useState(false);

    const reloadPage = useCallback(() => {
        setForceUpdate(!forceUpdate);
    }, [forceUpdate]);

    const [username, setUsername] = useState(getCookie("username")); // Use useState
    const [authLevel, setAuthLevel] = useState(getCookie("auth_level")); // Use useState

    // Use useEffect to update username and authLevel when a cookie changes
    useEffect(() => {
        setReloadState(reloadPage);
        const newUsername = getCookie("username");
        const newAuthLevel = getCookie("auth_level");
        if (newUsername !== username) {
            setUsername(newUsername);
        }
        if (newAuthLevel !== authLevel) {
            setAuthLevel(newAuthLevel);
        }
    }, [username, authLevel, reloadPage]);

    return (
        <Box sx={{
            width: "100%",
            height: '100vh',
            background: "rgb(25,25,25)",
            display: "flex",
            flexDirection: "column",
        }}>
            <SnackbarProvider maxSnack={3}>
                <Box sx={{ paddingTop: '3px' }}>
                    <Paper elevation={15}
                        sx={{
                            position: 'relative',
                            paddingBottom: 1,
                            margin: '4px',
                            marginTop: '0',
                            marginBottom: -10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: "space-between",
                            /* background: "linear-gradient(3deg, #161624 30%, #242444 70%)", */
                            boxSizing: 'border-box',
                            minHeight: '80px'
                        }}>
                        {username && (
                            <>
                                <Box sx={{
                                    paddingLeft: '17px',
                                    marginBottom: '-8px',
                                    display: "flex",
                                    alignItems: "center",
                                    width: drawerWidth,
                                    height: '80px',
                                    boxSizing: 'border-box',
                                }}>
                                    <Box>
                                        <Typography variant='subtitle1' >
                                            Welcome, {username}
                                        </Typography>
                                        {authLevel !== '0' && (
                                            <Typography variant='body2' color='textSecondary' fontSize={'1rem'}>
                                                Authorization Level: {authLevel}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                                <Button variant="contained"
                                    sx={{
                                        background: "linear-gradient(45deg, #df0000 10%, #f85a00 60%)",
                                        borderRadius: "20px",
                                        marginTop: "5px",
                                        marginRight: "20px",
                                    }}
                                    onClick={logout}>Logout</Button>
                            </>
                        )}
                        <Typography
                            //onClick={redirectToHome}
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                fontSize: '3.5rem',
                                fontFamily: "Roboto, sans-serif",
                                fontWeight: 500,
                                color: "rgb(200,200,200)",
                                alignSelf: "center",
                            }} >
                            SCClub
                        </Typography>
                    </Paper>
                </Box>
                <Outlet />
            </SnackbarProvider>
        </Box>
    )
}