import axios from 'axios';
import { getCookie, clearCookie } from './cookie';
import { SERVER_URL, redirectToDashboard, redirectToLogin, reloadState } from './globals';


export var is_admin: boolean = false;
export function set_is_admin(admin: boolean) {
    is_admin = admin;
    reloadState();
};
export var token_validated: boolean = false;
export function set_token_validated(valid: boolean) {
    token_validated = valid;
    reloadState();
};

export async function getIsAdmin() {
    if (!token_validated) {
        token_validated = await validate_token();
    }
    return is_admin;
}

export async function validate_token() {
    if (token_validated) {
        return token_validated;
    }
    if (getCookie('token') == undefined) {
        console.log("No token found");
        return false;
    }
    try {
        let result = await axios.get(SERVER_URL + '/session/validate', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('token')}`
            }
        });

        if ((result.status == 200) && (result.data.authorized == true)) {
            if (result.data.is_admin == true) {
                is_admin = true;
            }
            console.log("Token is valid");
            return true;
        }
    } catch (error: any) {
        if (error.response.status == 401) {
            console.log("Token Reset!");
            return false;
        }
    }
    return false;
}

export function alreadyLoggedIn() {
    if ((getCookie('username') != undefined) && (getCookie('token') != undefined)) {
        console.log("Already logged in");
        return true;
    }
    return false;
}


export function logout() {
    // Notify the server to invalidate the token
    axios.get(SERVER_URL + '/session/logout', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + getCookie('token'),
        }
    }).then(function (response) {
        console.log("Response from server");
        console.log(response.data);
        if (response.data.invalidated === true) {
            clearCookie('username');
            clearCookie('token');
            clearCookie('user_id');
            clearCookie('auth_level');
            redirectToLogin();
            reloadState();
            return true;
        }
    }
    ).catch(function (error) {
        console.log(error);
    });
    set_is_admin(false);
    set_token_validated(false);
    reloadState();
    return false;
}

export async function verify_if_logged_in() {
    console.log("Checking if already logged in");
    if (alreadyLoggedIn()) {
        if (await validate_token()) {
            redirectToDashboard();
        }
        else {
            console.log("Invalid token");
            clearCookie('username');
            clearCookie('token');
            clearCookie('user_id');
            clearCookie('auth_level');
        }
    }
    else {
        if (!(window.location.pathname.includes("login"))
            || window.location.pathname.includes("register")
            || window.location.pathname.endsWith("/")) {
            console.log("Redirecting to home");
            //redirectToHome();
        }
    }
};
