// App.tsx
import React, { useEffect } from 'react';
import {
  Route,
  createRoutesFromElements, createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import UserRegistration from './Register';
import UserLogin from './Login';
import DashboardApp, { DashLoader } from './Dashboard/Dashboard'
import { verify_if_logged_in } from './utils/session';
import { HomePage } from './HomePage';
import Layout from './Layout';


export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />} >
      <Route path="register" element={<UserRegistration />} />
      <Route path="login" element={<UserLogin />} />
      <Route index element={<HomePage />} />
      <Route path="dashboard" element={<DashboardApp />} loader={DashLoader} >
      </Route>
    </Route>
  )
)

const App: React.FC = () => {
  useEffect(() => {
    const timerId = setTimeout(async () => {
      await verify_if_logged_in();
    }, 1000);


    // Clear the timeout when the component is unmounted
    return () => clearTimeout(timerId);
  }, []);
  return (
    <RouterProvider router={router} />
  );
};



export default App;
