import React, { Suspense, lazy, useEffect, } from 'react';
import { getIsAdmin } from '../utils/session';
import { redirectToLogin, set_user_dash, UserDash } from '../utils/globals';
import { getCookie } from '../utils/cookie';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';


let Dash: any;

export const DashLoader = async () => {
    let is_admin = await getIsAdmin();
    console.log("Is Admin: " + is_admin);
    if (is_admin) {
        Dash = lazy(() => import('./Admin').then((module) => {
            return { default: module.AdminDash };
        }));
        console.log("Admin Dash Loaded");
        set_user_dash(Dash);
        return Dash;
    }
    else {
        Dash = lazy(() => import('./User').then((module) => {
            return { default: module.UserDash };
        }));
        console.log("casual Dash Loaded");
        set_user_dash(Dash);
        return Dash;
    }
}

const DashboardApp: React.FC = () => {
    useEffect(() => {
        // Check if we have a token
        let token = getCookie('token');
        if (token === undefined || token.length < 5) {
            console.log("No token found, redirecting to login");
            redirectToLogin();
        }
    });
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>

            <Suspense fallback={<div>Loading...</div>}>
                <UserDash />
            </Suspense>
        </LocalizationProvider>

    );
};

export default DashboardApp;
