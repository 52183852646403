import Cookies from 'js-cookie';


export const setCookie = (name: string, value: string, days: number) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    Cookies.set(name, value, { path: '/', expires: expirationDate, sameSite: 'Strict', secure: false });
};


// Fuction to get the value of a cookieion to get the value of a cookie
export const getCookie = (name: string): string | undefined => {
    return Cookies.get(name);
};

export const clearCookie = (name: string) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
}

