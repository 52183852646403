import { router } from "../App";
import { enqueueSnackbar } from "notistack";

export const drawerWidth = 260;

export var UserDash: React.FC;
export function set_user_dash(dash: React.FC) {
    UserDash = dash;
}


export var reloadState: any;
export var setReloadState: (state: any) => void = (state: any) => {
    reloadState = state;
};

export const SERVER_URL = '';

export function redirectToDashboard(replace: boolean = true) {

    if (!window.location.href.includes("dashboard")) {
        router.navigate('/dashboard', { replace });
    }
}

export function redirectToHome() {
    router.navigate('/');
}

export function redirectToLogin() {
    router.navigate('/login');
}

export function redirectToregister() {
    router.navigate('/register');
}

// Error handling 
export var errorMessage: string = '';
export var setErrorMessage: (error: string) => void = (error: string) => {
    enqueueSnackbar(error, { variant: 'error' });
    //errorMessage = error; // wow this is some hot garbage
    //reloadState();
};

export function setInformationMessage(message: string) {
    enqueueSnackbar(message, { variant: 'info' });
}

export var successMessage: string = '';
export var setSuccessMessage: (error: string) => void = (error: string) => {
    enqueueSnackbar(error, { variant: 'success' });
    //successMessage = error; // wow this is some hot garbage
    // reloadState();
};