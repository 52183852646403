// UserLogin.tsx
import React, { useState } from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { setCookie, getCookie } from './utils/cookie';
import { redirectToDashboard, reloadState, setErrorMessage, setSuccessMessage } from './utils/globals';

const UserLogin: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [LoginButtonDisabled, setLoginButtonDisabled] = useState(false);
    const handleLogin = () => {
        setLoginButtonDisabled(true);
        // Call your login API endpoint here with username and password
        console.log('Logging in user:', { username, password });
        if (username.length < 3 || password.length < 5) {
            setErrorMessage('Username and password are required');
            setLoginButtonDisabled(false);
            return;
        }
        // Send a POST request

        axios.post('/user/authorize', {
            username: username,
            password: password,
            token_length: 90, // 90 days
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                console.log("Response from server");
                console.log(response.data);

                if (response.status === 200 &&
                    response.data.authorized === true &&
                    response.data.username === username
                ) {
                    setCookie('username', username, 90);
                    setCookie('token', response.data.token, 90);
                    setCookie('user_id', response.data.user_id, 90);
                    setCookie('auth_level', response.data.auth_level, 90);
                    console.log("Login successful");
                    console.log("username set : " + getCookie('username'));
                    console.log("token set : " + getCookie('token'));
                    console.log("user_id set : " + getCookie('user_id'));
                    console.log("auth_level set : " + getCookie('auth_level'));


                    setSuccessMessage('Login successful, Redirecting...');
                    reloadState();
                    setTimeout(redirectToDashboard, 3000);
                    return;
                } else {
                    setErrorMessage('Invalid username or password');
                    return;
                }
            })
            .catch(function (error) {
                if (axios.isAxiosError(error)) {
                    const axiosError = error as AxiosError; // Type assertion
                    if (axiosError.response && axiosError.response.data) {
                        // If the response has a body, use it as the error message
                        //setError("Error : " + axiosError.response.data as string);
                        setErrorMessage("Error : " + axiosError.response.data as string);
                    } else {
                        setErrorMessage('Error Fetching Files. Please try again later.');
                    }
                } else {
                    // Handle other types of errors if needed
                    setErrorMessage('Error Fetching Files. Please try again later.');
                }
                console.log(error);
                setUsername('');
                setPassword('');
                setLoginButtonDisabled(false);
            });
    };


    return (
        <Box sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "100vh",
        }}>
            <Paper elevation={5}
                sx={{
                    p: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 4,
                }}>
                <Typography color="textPrimary" variant="h5">
                    User Login
                </Typography>
                <Box>
                    <TextField
                        label="Username"
                        id='username'
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                        disabled={LoginButtonDisabled}
                        inputProps={{ maxLength: 50 }}
                    />

                    {/* <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    /> */}
                </Box>
                <Box>
                    <TextField
                        id="standard-password-input"
                        type="password"
                        label="Password"
                        variant="outlined"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        disabled={LoginButtonDisabled}
                        inputProps={{ maxLength: 50 }}
                    />
                </Box>
                <Button variant="contained" onClick={handleLogin}
                    disabled={LoginButtonDisabled}
                >Login</Button>
            </Paper>
        </Box>
    );
};

export default UserLogin;
